import Axios, { AxiosAdapter, AxiosError, AxiosInstance, AxiosTransformer } from "axios";
import humps from "humps";

import { HvcoResource } from "./resources/HvcosResource";
import { TermResource } from "./resources/TermsResource";

export class Api {
  term: TermResource;
  hvco: HvcoResource;

  private axios: AxiosInstance;
  constructor(adapter?: AxiosAdapter) {
    if (!process.env.APP_API_URL) {
      throw new Error("APP_API_URL not provided");
    }

    // TODO: Check if transformRequest is necessary
    this.axios = Axios.create({
      baseURL: process.env.APP_API_URL,
      transformResponse: [
        ...(Axios.defaults.transformResponse as AxiosTransformer[]),
        data => (data instanceof Blob ? data : humps.camelizeKeys(data))
      ],
      headers: {
        "Content-Type": "application/vnd.api+json"
      },
      adapter
    });

    this.axios.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        if (Axios.isCancel(error)) {
          throw error;
        }
        throw error;
      }
    );
    this.term = new TermResource(this.axios);
    this.hvco = new HvcoResource(this.axios);
  }
}
