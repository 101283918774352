import get from "lodash/get";
import omit from "lodash/omit";

import { extractToUpperLevel } from "../../../../utils/language";
import { Entity, EntityBlueprint, RelationshipsConfigRoot } from "./types";

export function serialize<E extends Entity>({
  type,
  data,
  relationships
}: {
  type: string;
  data: EntityBlueprint<E>;
  relationships: RelationshipsConfigRoot<E>;
}) {
  const flatRelationships = extractToUpperLevel(relationships, "relationships");

  const serializedRelationships: any = Object.entries(flatRelationships || {}).reduce((acc, [relationName, relationConfig]: any[]) => {
    const relationData = get(data, relationName, null);

    if (!relationData) {
      return acc;
    }

    if (Array.isArray(relationData)) {
      return Object.assign(acc, {
        [relationName]: {
          data: (relationData as Array<EntityBlueprint<E>>).map(eachRelationData => {
            const serializedEntity = serialize({
              type: relationConfig.type,
              data: eachRelationData,
              relationships: get(relationships, [relationName], {}) as any
            });

            return serializedEntity.data;
          })
        }
      });
    }

    return Object.assign(acc, {
      [relationName]: serialize({
        type: relationConfig.type,
        data: relationData,
        relationships: get(relationships, [relationName], {}) as any
      })
    });
  }, {});

  const serializedAttributes = omit(data, [...Object.keys(flatRelationships), "id"]);

  const spreadAttributes = Object.keys(serializedAttributes).length ? { attributes: serializedAttributes } : {};

  const spreadRelationships = Object.keys(serializedRelationships).length ? { relationships: serializedRelationships } : {};

  const spreadId = data.id ? { id: data.id } : {};

  return {
    data: {
      type,
      ...spreadId,
      ...spreadAttributes,
      ...spreadRelationships
    }
  };
}
