import { AxiosInstance, AxiosRequestConfig } from "axios";

import { buildUrl } from "./utils/buildUrl";
import { deserialize } from "./utils/deserialize";
import { serialize } from "./utils/serialize";
import { Entity, EntityBlueprint, FlatEntity, RelationshipsConfig, RelationshipsConfigRoot, UrlConfig } from "./utils/types";

type RequestConfig<E extends Entity> = AxiosRequestConfig & {
  relationships?: Partial<
    {
      [Key in keyof E["relationships"]]: RelationshipsConfig<MaybeArrayElement<E["relationships"][Key]>>;
    }
  >;
};

export class Resource<E extends Entity> {
  constructor(
    protected axios: AxiosInstance,
    protected config: {
      prefixes?: string[];
      type: string;
      relationships?: RelationshipsConfigRoot<E>;
    }
  ) {}

  async create<Blueprint = undefined, T = Blueprint>(
    entityBlueprint: Blueprint extends undefined ? Partial<EntityBlueprint<E>> : T,
    axiosConfig?: RequestConfig<E>,
    urlConfig?: UrlConfig<E>
  ): Promise<Array<FlatEntity<E>>> {
    const { data } = await this.axios.post(
      this.buildUrl(urlConfig),
      serialize<E>({
        type: this.config.type,
        data: entityBlueprint as any,
        relationships: this.config.relationships as any
      }),
      axiosConfig
    );

    return deserialize(data);
  }

  async update<Blueprint = undefined, T = Blueprint>(
    id: string,
    entityBlueprint: Blueprint extends undefined ? Partial<EntityBlueprint<E>> : T,
    axiosConfig?: RequestConfig<E>,
    urlConfig?: Omit<UrlConfig<E>, "id">
  ): Promise<Array<FlatEntity<E>>> {
    const { data } = await this.axios.patch(
      this.buildUrl({ id, ...urlConfig }),
      serialize<E>({
        type: this.config.type,
        data: { id, ...entityBlueprint } as any,
        relationships: this.config.relationships as any
      }),
      axiosConfig
    );

    return deserialize(data);
  }

  async delete(id: string, axiosConfig?: RequestConfig<E>, urlConfig?: Omit<UrlConfig<E>, "id">) {
    return this.axios.delete(this.buildUrl({ id, ...urlConfig }), axiosConfig);
  }

  async find(providedConfig?: UrlConfig<E> | null, axiosConfig?: RequestConfig<E>) {
    const config = providedConfig ? providedConfig : {};
    const { data } = await this.axios.get(this.buildUrl(config), axiosConfig);
    const deserializedData = deserialize(data);

    return {
      data: deserializedData as Array<FlatEntity<E>>,
      meta: data.meta
    };
  }

  async findById(
    id: string,
    providedConfig?: Omit<UrlConfig<E>, "filter" | "sort" | "pagination" | "id"> | null,
    axiosConfig?: RequestConfig<E>
  ) {
    const config = providedConfig ? providedConfig : {};
    const {
      data: [first],
      meta
    } = await this.find({ id, ...config }, axiosConfig);

    return { data: first, meta };
  }

  private buildUrl(config: UrlConfig<E> = {}) {
    const prefixes = config.prefixes || this.config.prefixes || [];
    return buildUrl<E>([...prefixes, this.config.type].join("/"), config);
  }
}
