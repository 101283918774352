import humps from "humps";
import qs from "qs";

import { Entity, UrlConfig } from "./types";

export function createIncludeList<E extends Entity>(inputConfig: NonNullable<UrlConfig<E>["include"]>, prefix = ""): string {
  const includeConfig = inputConfig;

  if (!includeConfig) {
    return "";
  }

  return Object.keys(includeConfig)
    .reduce((acc: string[], key) => {
      const currentInclude = includeConfig[key];

      if (typeof currentInclude === "object") {
        const nestedInclude = createIncludeList(currentInclude as object, prefix ? `${prefix}${key}.` : `${key}.`);
        return [...acc, nestedInclude];
      }

      if (currentInclude) {
        return [...acc, `${prefix}${key}`];
      }

      return acc;
    }, [])
    .join(",");
}

export function buildUrl<T extends Entity>(type: string, configParam?: UrlConfig<T>) {
  type Config = UrlConfig<T>;

  if (!configParam) {
    return type;
  }

  const config = {
    filter: {} as NonNullable<Config["filter"]>,
    sort: [] as T extends Entity ? NonNullable<Config["sort"]> : never[],
    include: {} as T extends Entity ? NonNullable<Config["include"]> : {},
    fields: [] as T extends Entity ? NonNullable<Config["fields"]> : never[],
    ...configParam
  };

  const sort = config.sort
    .map(([attribute, sortingOrder]) => {
      return sortingOrder === "ASC" ? attribute : `-${attribute}`;
    })
    .join(",");

  const fields = (config.fields as string[])
    .reduce((acc: string[], key) => {
      return [...acc, key];
    }, [])
    .join(",");

  const include = humps.decamelize(createIncludeList(config.include));

  const query = qs.stringify(
    humps.decamelizeKeys({
      filter: config.filter,
      sort: sort.length > 0 ? humps.decamelize(sort) : undefined,
      fields: fields.length > 0 ? { [type]: humps.decamelize(fields) } : undefined,
      include: include.length > 0 ? humps.decamelize(include) : undefined,
      page: config.pagination
    }),
    { encode: false, arrayFormat: "comma" }
  );

  const resourcePath = config.id ? `${type}/${config.id}` : type;

  const url = query.length > 0 ? `${resourcePath}?${query}` : resourcePath;

  return url;
}
