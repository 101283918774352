import { AxiosInstance } from "axios";

import { FileEntity } from "../entities/FileEntity";
import { Resource } from "../Resource/Resource";
import { Entity, EntityAttributes, EntityRelationships } from "../Resource/utils/types";

export interface TermAttributes extends EntityAttributes {
  account: string;
  term: string;
  status: string;
}

interface TermRelationships extends EntityRelationships {
  tFiles: FileEntity[];
  pFiles: FileEntity[];
  iFiles: FileEntity[];
}

type TermEntity = Entity<TermAttributes, TermRelationships>;
export class TermResource extends Resource<TermEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "terms",
      relationships: {
        tFiles: {
          type: "term_files"
        },
        pFiles: {
          type: "privacy_files"
        },
        iFiles: {
          type: "imprint_files"
        },
        agreements: {
          type: "agreements"
        }
      }
    });
  }
}
