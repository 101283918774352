import { get, isEmpty, map, omit, pickBy } from "lodash";

export function toArray(arrayOrObject: any[] | object) {
  return Array.isArray(arrayOrObject) ? arrayOrObject : [arrayOrObject];
}

export function extractToUpperLevel(resource: any, property: string) {
  const attributes = get(resource, property, {});

  return {
    ...attributes,
    ...omit(resource, [property])
  };
}

export function pickNotEmpty<T = any>(object: Dictionary<T> | null | undefined) {
  return pickBy(object, eachValue => !isEmpty(eachValue));
}

export function passNullOrBoolean(value: any) {
  if (value === null) {
    return null;
  }

  return !!value;
}

export function isTruthy(value: any) {
  return !!value;
}

export function required<T extends {}>(object: T) {
  map(object, (key, value) => {
    if (value === null || value === undefined) {
      throw new Error(`${key} cannot be null nor undefined`);
    }
  });

  return object as {
    [Key in keyof T]: NonNullable<T[Key]>;
  };
}
