import { isEmpty, keyBy, mapValues, omit, omitBy, toArray } from "lodash";

import { extractToUpperLevel } from "../../../../utils/language";
import { mergeWithIncluded } from "./mergeWithIncluded";
import { Document, Entity, FlatEntity } from "./types";

/**
 * It merges included data with entities and extract attributes and relationships to the top of the each entity object.
 */
export function formatRelationships<E extends Entity>(includedByIdAndType: any, eachEntity: any) {
  if (eachEntity.relationships) {
    const formattedRelationships = Object.entries(eachEntity.relationships).reduce((acc, [relationName, relationData]) => {
      const formatRelationData = (eachRelation: any) => {
        const relationWithIncluded = mergeWithIncluded(includedByIdAndType, eachRelation);
        const extractedData = extractToUpperLevel(relationWithIncluded, "data");
        const extractedAttributes = extractToUpperLevel(extractedData, "attributes");
        const nestedRelationships = formatRelationships(includedByIdAndType, extractedAttributes);
        const extractedRelationships = extractToUpperLevel(nestedRelationships, "relationships");

        return extractedRelationships;
      };

      return Object.assign(acc, {
        [relationName]: Array.isArray(relationData) ? relationData.map(formatRelationData) : formatRelationData(relationData)
      });
    }, {});

    return {
      ...omit(eachEntity, ["relationships", "attributes"]),
      ...omitBy(formattedRelationships, value => isEmpty(value))
    } as FlatEntity<E>;
  }

  return eachEntity;
}

export function deserialize<E extends Entity>(document: { data: Document | Document[]; included: Document[] }) {
  const primaryData = toArray(document.data).map(value => omit(value, "links"));
  const includedByIdAndType = mapValues(
    keyBy(document.included || [], ({ id, type }) => id + type),
    value => omit(value, "links")
  );
  const resources = primaryData.map(eachEntity => extractToUpperLevel(eachEntity, "attributes"));

  const entitiesWithIncluded = resources
    .map(eachEntity => mergeWithIncluded(includedByIdAndType, eachEntity))
    .map(eachEntity => formatRelationships(includedByIdAndType, eachEntity));

  return entitiesWithIncluded as Array<FlatEntity<E>>;
}
