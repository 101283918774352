import { AxiosInstance } from "axios";

import { FileEntity } from "../entities/FileEntity";
import { Resource } from "../Resource/Resource";
import { Entity, EntityAttributes, EntityRelationships } from "../Resource/utils/types";

export interface HvcoAttributes extends EntityAttributes {
  account: string;
  hvco: string;
  status: string;
}

interface HvcoRelationships extends EntityRelationships {
  hFiles: FileEntity[];
}

type HvcoEntity = Entity<HvcoAttributes, HvcoRelationships>;
export class HvcoResource extends Resource<HvcoEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "hvcos",
      relationships: {
        hFiles: {
          type: "hvco_files"
        }
      }
    });
  }
}
